<template>
  <div>
    <header>
      <h5>{{ data.totalList && data.totalList.length }}</h5>
      <p>人员总数</p>
    </header>
    <div class="part">
      <h5 class="title">境内人员 {{ data.inList && data.inList.length }}</h5>
      <div class="table">
        <div class="thead">
          <span class="tr">机构名称</span>
          <span class="tr">管理人员</span>
          <span class="tr">务工人员</span>
          <!-- <span class="tr">回国人员</span> -->
          <span class="tr">待派出</span>
        </div>
        <div
          class="trow"
          v-for="item in data.inInsList"
          :key="item.id"
          v-if="data.inInsList"
        >
          <span class="tr" @click="jump(item)">{{ item.institution }}</span>
          <span class="tr" @click="goList(item.managerList)">{{
            item.managerList.length
          }}</span>
          <span class="tr" @click="goList(item.serviceList)">{{
            item.serviceList.length
          }}</span>
          <!-- <span class="tr" @click="goList(item.returnList)">{{
            item.institution === "中建国际总部" ? item.length : "-"
          }}</span> -->
          <span class="tr" @click="goList(item.waitSendList)">{{
            item.institution === "中建国际总部" ? item.waitSendList.length : "-"
          }}</span>
        </div>
      </div>
    </div>
    <div class="part">
      <h5 class="title">境外人员 {{ data.outList && data.outList.length }}</h5>
      <div class="table">
        <div class="thead">
          <span class="tr">机构名称</span>
          <span class="tr">管理人员</span>
          <span class="tr">务工人员</span>
          <span class="tr">出差人员</span>
        </div>
        <div
          class="trow"
          v-for="item in data.outInsList"
          :key="item.id"
          v-if="data.outInsList"
        >
          <span class="tr" @click="jump(item)">{{ item.institution }}</span>
          <span class="tr" @click="goList(item.managerList)">{{
            item.managerList.length
          }}</span>
          <span class="tr" @click="goList(item.serviceList)">{{
            item.serviceList.length
          }}</span>
          <span class="tr" @click="goList(item.businessTripList)">{{
            item.businessTripList.length
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPersonListInfo } from "@/api/api";
export default {
  data() {
    return {
      data: {},
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      let res = await getPersonListInfo();
      this.data = res.data.data;
    },
    jump(item) {
      console.log(item)
      if(item.institution === '机构回国人员' || item.institution === '国内出差人员'){
        return 
      }
      this.$store.commit("SET_PROJECT_FORM", { institution: item.institution });
      this.$router.push("/returnwork");
    },
    goList(item) {
      this.$router.push({
        name: "ReturnWorkList",
        query: {
          list: JSON.stringify(item),
        },
      });
    },
  },
};
</script>

<style scoped>
header {
  height: 1rem;
  background: #2f7dcd;
  padding: 0.1rem 0.23rem;
  color: #fff;
}
header h5 {
  font-size: 0.4rem;
  line-height: 1;
  margin-top: 0.1rem;
}
header p {
  font-size: 0.13rem;
  margin-top: 0.05rem;
}
.part {
  margin-bottom: 0.1rem;
}
.part .title {
  color: #333333;
  font-size: 0.16rem;
  padding: 0.12rem 0.15rem;
}
.table .thead {
  display: flex;
  background: rgba(59, 115, 198, 0.05);
  line-height: 0.4rem;
  color: #999999;
  font-size: 0.12rem;
}
.table .tr {
  flex: 1;
  text-align: center;
  font-weight: bold;
}
.table .tr:first-child {
  flex: 1.8;
  font-weight: normal;
}
.trow {
  border-bottom: 1px solid #e0e0e0;
  line-height: 0.5rem;
  color: #333333;
  font-size: 0.14rem;
  display: flex;
}
</style>